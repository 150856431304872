function vectorToString(vec) {
    return `(${vec.x.toFixed(2)}, ${vec.y.toFixed(2)}, ${vec.z.toFixed(2)})`;
}

/**
 * checks if n ∈ [range.a, range.b)
 * @param {number} n the number to check
 * @param {{a: number, b: number}} range the range left inclusive, right inclusive
 * @returns
 */
function isInRange(n, range) {
    return n >= range.a && n < range.b;
}
